import React from 'react'
import PropTypes from 'prop-types'

// Generic
import Container from '../../shared/Container/Container'
import Button from '../../shared/Button/Button'
import Link from '../../shared/Link/Link'

// Icons
import ChevronRight from '../../../assets/img/chevron-right.svg'

const PressHighlights = (props) => {
  const { rows, title, ctaUrl } = props

  return (
    <Container width='thin'>
      {title && <h2 className='mb-10'>{title}</h2>}
      {rows.length && (
        <ul className='divide-y divide-gray-lighter border-t border-b border-gray-lighter mb-12'>
          {rows.map((pressHighlight) => {
            return (
              <li
                key={pressHighlight.id}
              >
                <Link
                  className='flex items-center py-8 group'
                  {...pressHighlight.data.link}
                >
                  <div>
                    <p className='text-xs uppercase font-medium tracking-wider text-opacity-75 text-midnight mb-2'>{pressHighlight.data.publication.text}, {pressHighlight.data.date}</p>
                    <h3 className='h5 group-hover:text-primary'>{pressHighlight.data.title.text}</h3>
                  </div>
                  <ChevronRight className='text-primary h-3 fill-current ml-auto ml-2 flex-grow-0 flex-shrink-0' />
                </Link>
              </li>
            )
          })}
        </ul>
      )}
      {ctaUrl && (
        <div className='text-center mb-24'>
          <Button
            color='inline'
            icon
            url={ctaUrl}
            linkType='Document'
          >
            View More
          </Button>
        </div>
      )}
    </Container>
  )
}

PressHighlights.propTypes = {
  rows: PropTypes.array.isRequired,
  title: PropTypes.string,
  ctaUrl: PropTypes.string
}

export default PressHighlights
