import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

// Layout
import Seo from '../../components/layout/Seo/Seo'

// Content Components
import HeroMinimal from '../../components/elements/HeroMinimal/HeroMinimal'

// Generic
import PressHighlights from '../../components/shared/PressHighlights/PressHighlights'

const PressHighlightsPage = ({ data }) => {
  return (
    <>
      <Seo
        title='Press Highlights'
      />
      <HeroMinimal>
        <h1>Press Highlights</h1>
      </HeroMinimal>
      <section className='py-20'>
        <PressHighlights
          rows={data.highlights.nodes}
        />
      </section>
    </>
  )
}

PressHighlightsPage.propTypes = {
  data: PropTypes.object.isRequired
}

export default PressHighlightsPage

export const PageQuery = graphql`
  query PressHighlightsPageQuery {
    highlights: allPrismicPressHighlight(sort: {fields: data___date, order: DESC}) {
      nodes {
        id
        data {
          title {
            text
          }
          publication {
            text
          }
          link {
            ...Link
          }
          date(formatString: "D MMMM YYYY")
        }
      }
    }
  }
`
